.address-book-page__overlay {
  .address-form {
    .form-item--full {
      width: 100%;
    }
  }
}

.profile {
  &-page {
    margin: auto;
    .profile-info,
    .optional-info,
    .privacy-policy,
    .pro-member-info,
    .newsletter-info,
    .sms-promotions,
    .sms-info {
      &__prefix {
        display: inline-block;
        float: left;
        padding: 0 10px;
        height: 40px;
        line-height: 40px;
        border: 1px solid black;
        margin-right: 1%;
      }
      &__number-field {
        display: inline-block;
      }
    }
  }
}

.orders-list {
  .tracking_disclaimer {
    display: none;
    max-width: 400px;
  }
  .tracking_link:hover ~ .tracking_disclaimer {
    display: block;
    background-color: $color-white;
    color: $color-black;
    border: 1px solid $color-black;
    border-radius: 6px;
    font-size: 11px;
    width: 198px;
    text-transform: unset;
    position: absolute;
    bottom: 15px;
  }
}

.orders-list__item {
  .orders-list__table--tracking-number {
    position: relative;
  }
}

.orders-list__item ul {
  overflow: visible;
  min-height: 25px;
}

.favorites-page {
  .original-price {
    text-decoration: line-through;
    margin-right: 5px;
  }
  .sale-price {
    color: $color-red;
    display: inline-block;
  }
}
