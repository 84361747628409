@mixin continue-checkout {
  width: 325px;
  height: 45px;
  line-height: 34px;
}

@mixin mobile_sticky_bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  z-index: 99999;
  font-weight: normal;
  font-size: 16px;
  padding: 0;
  line-height: 60px;
  height: 80px;
  border: 10px solid $color-white;
  visibility: visible;
  display: inline;
}

@mixin checkout__button {
  width: 100%;
  @include breakpoint($large-up) {
    @include continue-checkout();
  }
}

.checkout {
  @include clearfix;
  @include breakpoint($small-down) {
    overflow: visible;
  }
  position: relative;
  margin: 0 auto;
  @include breakpoint($medium-up) {
    padding: 1em;
  }
  max-width: $max-width;
  z-index: 1;
  &-cart-pg,
  &-samples-pg {
    .sticky__footer__menu {
      display: none;
    }
  }
  &-opc {
    .sticky__footer__menu {
      display: none;
    }
  }
  &__header {
    position: relative;
    padding: 1em;
    width: 100%;
    border-bottom: 1px solid $color-light-gray;
  }
  &__heading {
    @include header--large;
    display: inline-block;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  }
  &.checkout-single-page {
    .checkout-panel {
      &.finished,
      &.collapsed {
        display: none;
        @include breakpoint($medium-up) {
          display: block;
        }
      }
    }
  }
  .checkout-progress-bar {
    margin: 20px 0 0;
    &__list {
      overflow: hidden;
      counter-reset: step;
      width: 100%;
      margin: 0;
      padding: 0;
    }
    &__list-item {
      color: $color-gray;
      text-transform: uppercase;
      width: 33.33%;
      float: left;
      position: relative;
      text-align: center;
      font-size: 12px;
      font-family: $font--avenir-heavy;
      a {
        border-bottom: none;
        color: $color-gray;
        text-decoration: none;
        &:hover,
        &:visited {
          color: $color-gray;
          text-decoration: none;
        }
      }
      &:before {
        @include border-radius(7.5px);
        content: counter(step);
        counter-increment: step;
        width: 15px;
        line-height: 15px;
        display: block;
        color: $color-gray;
        background: $color-gray;
        margin: auto;
        margin-bottom: 5px;
      }
      &:after {
        content: '';
        width: 100%;
        height: 8px;
        background: $color-gray;
        position: absolute;
        left: -50%;
        top: 4px;
        z-index: -1;
      }
      &:first-child:after {
        content: none;
      }
      &--complete {
        color: $color-black;
        font-weight: bold;
        a {
          color: $color-black;
          &:hover,
          &:visited {
            color: $color-black;
          }
        }
        &:before {
          background: $color-black;
          color: $color-black;
        }
      }
    }
  }
  &__content {
    @include breakpoint($medium-up) {
      float: left;
      width: 66.6666666667%;
      border-#{$rdirection}: 1px solid $color-light-gray;
    }
    .gift-message__container {
      margin-top: 15px;
    }
    fieldset {
      width: auto;
      max-width: none;
      margin-right: 0;
      &.fs {
        max-width: 45em;
        width: 100%;
      }
    }
    .payment-display-content {
      &__heading {
        @include header--medium;
      }
    }
    .address-options {
      @include breakpoint($large-up) {
        width: 33.3333333%;
      }
      &:nth-child(3n + 4) {
        @include breakpoint($large-up) {
          clear: both;
        }
      }
      &__address-container {
        height: 140px;
      }
      &__select-this-address {
        // Hide --selected span at first
        .select-label--selected {
          display: none;
        }

        // If it's the the selected address, show --selected and hide the normal span
        &.selected {
          .select-label {
            display: none;
            &--selected {
              display: inline-block;
            }
          }
        }
      } // &__select-this-address
    } // .address-options
    .messages {
      padding: 0;
      margin: 1em;
      @include breakpoint($medium-up) {
        margin: 1em 0.5em;
      }
      color: $color-red;
      background-color: transparent;
      border-width: 0;
      &.error a {
        color: $color-pink;
      }
    }
    .single-message {
      color: $color-error-msg;
      a:link,
      a:visited {
        color: $color-error-msg;
      }
      &:first-child {
        padding-top: 20px;
      }
      &[id^='error_offer_criteria_met'],
      &.success {
        color: $color-success-msg;
        a:link,
        a:visited {
          color: $color-success-msg;
        }
      }
      &[id^='error_offer_deferred'],
      &.threshold-messages {
        color: $color-alert-msg;
        a:link,
        a:visited {
          color: $color-alert-msg;
        }
      }
    }
    .warning-message {
      margin-bottom: 1em;
      color: $color-red;
      font-size: 1.2em;
      font-weight: bold;
    }
  } // &__content

  &__sidebar {
    background-color: $color-lighter-gray;
    @include breakpoint($medium-up) {
      float: left;
      width: 33.3333333333%;
    }
    .checkout-button {
      width: 100%;
      @include breakpoint($medium-up) {
        height: 55px;
        font-size: 16px;
        line-height: 3.5;
      }
      &.checkout-panel__content {
        @include breakpoint($medium-up) {
          padding: 0;
        }
      }
    }
    .checkout-panel--checkout-button {
      display: none;
      @include breakpoint($medium-up) {
        padding: 0;
        display: block;
      }
    }
  }
  &__footer {
    @include clearfix;
    clear: both;
  }
} // .checkout

.checkout-opc {
  &.active-panel-signin {
    .site-footer,
    .gnav-util--search,
    .gnav-util--cart {
      display: block;
    }
    .gnav-util--locator {
      display: block;
      @include breakpoint($medium-up) {
        display: none;
      }
    }
    .checkout-single-page {
      padding: 0;
    }
  }
  .site-footer,
  .gnav-util--search,
  .gnav-util--cart {
    display: none;
    @include breakpoint($medium-up) {
      display: block;
    }
  }
  .gnav-util--locator {
    display: none;
  }
}

.active-panel-registration {
  .site-footer,
  .gnav-util--search,
  .gnav-util--cart {
    display: none;
    @include breakpoint($medium-up) {
      display: block;
    }
  }
  .gnav-util--locator {
    display: none;
  }
  .checkout--confirmation-page {
    padding-bottom: 45px;
    @include breakpoint($medium-up) {
      padding-bottom: 0;
    }
  }
}

.checkout-panel {
  @include clearfix;
  position: relative;
  padding: 1em;
  border-bottom: 1px solid $color-light-gray;
  &:last-child {
    @include breakpoint($medium-up) {
      border-bottom-width: 0px;
    }
  }
  .links-content {
    a {
      font-size: 12px;
    }
    p {
      margin-bottom: 0;
    }
  }
  &:first-child {
    border-top-width: 0px;
  }
  #offer_code {
    margin-top: 1em;
  }
  .checkout-panel {
    padding: 0;
    @include breakpoint($small-down) {
      padding-top: 1em;
    }
    border: none;
    label {
      padding-left: 10px;
    }
  }
  .checkout-panel--return-user {
    .js-forgot-password {
      font-size: 12px;
    }
    margin-top: 9px;
  }
  .checkout-panel--email-and-sms-promotions {
    margin-top: 20px;
  }
  &--samples,
  &--new-account,
  &--return-user,
  &--payment,
  &--review {
    .checkout-panel__content {
      .continue-checkout {
        width: 100%;
        @include breakpoint($large-up) {
          @include continue-checkout();
        }
      }
    }
  }
  &--shipping {
    .checkout-panel__content {
      .checkout__button {
        @include checkout__button;
      }
    }
  }
  &__header {
    position: relative;
    margin: 0.5em 0;
    padding: 0;
    @include breakpoint($medium-up) {
      .checkout__sidebar & {
        padding: 0 0.5em;
      }
    }
    width: 100%;
    font-weight: bold;
    .edit {
      float: right;
      margin-top: 5px;
      text-transform: uppercase;
      font-weight: normal;
    }
  }
  &__heading {
    @include header--medium;
    display: inline-block;
    margin: 0;
    margin-bottom: 0.5em;
    padding: 0;
    text-transform: none;
    font-family: $font--avenir-heavy;
  }
  .checkout__panel--heading {
    @include header--small;
    font-family: $font--avenir-medium;
  }
  &__content {
    margin: 0;
    padding: 0;
    @include breakpoint($medium-up) {
      .checkout__sidebar & {
        padding: 0 0.5em;
      }
    }
    &.mobile_sticky_bottom {
      @include mobile_sticky_bottom();
    }
    .mobile_sticky_bottom input[type='submit'] {
      @include mobile_sticky_bottom();
    }
  } // &__content

  .continue-checkout {
    width: 100%;
    @include breakpoint($large-up) {
      @include continue-checkout();
    }
  }
  .continue-button-wrapper {
    text-align: left;
    width: auto;
  }
  .messages {
    padding: 0;
    margin: 0;
    color: $color-red;
    background-color: transparent;
    border-width: 0;
    &.error a {
      color: $color-pink;
    }
  }
  .single-message {
    color: $color-error-msg;
    a:link,
    a:visited {
      color: $color-error-msg;
    }
    &[id^='error_offer_criteria_met'],
    &.success {
      color: $color-success-msg;
      a:link,
      a:visited {
        color: $color-success-msg;
      }
    }
    &[id^='error_offer_deferred'],
    &.threshold-messages {
      color: $color-alert-msg;
      a:link,
      a:visited {
        color: $color-alert-msg;
      }
    }
    &:first-child {
      padding: 1em 0;
      @include breakpoint($medium-up) {
        padding: 0;
      }
    }
  }
  .warning-message {
    margin-bottom: 1em;
    color: $color-red;
    font-size: 1.2em;
    font-weight: bold;
  }

  // Panel loading animation
  .loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: white url(/images/global/ajax-loading.gif) no-repeat center center;
    text-align: center;
    opacity: 0;
    filter: alpha(opacity=00);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=00)';
    &.fade-in {
      opacity: 0.8;
      filter: alpha(opacity=80);
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
    }
    &:before {
      content: '';
      display: inline-block;
      margin-right: -0.25em;
      height: 100%;
      vertical-align: middle;
    }
    .text {
      display: inline-block;
      padding-top: 50px;
      width: 100px;
      vertical-align: middle;
    }
    // End of panel loading animation
  }
  .submit input[disabled='disabled'] {
    cursor: not-allowed;
    opacity: 0.5;
  }
} // .checkout-panel

.checkout-buttons-content {
  padding-top: 20px;
  .continue-shopping {
    margin-top: 12px;
    display: inline-block;
    font-size: 12px;
  }
  .go-shopping {
    margin-bottom: 1em;
  }
  &__container {
    .mobile_sticky_bottom {
      @include mobile_sticky_bottom();
    }
    @include breakpoint($small-up) {
      text-align: center;
    }
    @include breakpoint($large-up) {
      width: 70%;
      float: #{$ldirection};
      text-align: #{$ldirection};
    }
  }
  &__item {
    display: inline-block;
    @include breakpoint($small-up) {
      margin-top: 0;
    }
    &.continue-checkout {
      width: 100%;
      @include breakpoint($large-up) {
        @include continue-checkout();
      }
    }
    &--choose-samples {
      display: none;
    }
  }
  span.or-text {
    display: none; //temporary while no samples page
    //display: block;
    margin: 0 0 7px;
    width: 100%;
    @include breakpoint($large-up) {
      width: 10%;
      margin: 0 3%;
      //display: inline-block;
    }
    text-align: center;
    text-transform: uppercase;
  }
  .paypal-checkout {
    display: none; //temporary while no samples page
    //display: inline-block;
    float: left;
    padding: 1px;
    width: 40%;
    height: 40%;
    img {
      margin-top: 12%;
    }
    @include breakpoint($small-up) {
      float: none;
      width: auto;
      margin-bottom: 7px;
      padding: 0;
      line-height: inherit;
      img {
        vertical-align: bottom;
        margin-top: 12px;
      }
    }
  } // .paypal-checkout
} // .checkout-buttons-content

// PG Viewcart
.checkout.viewcart {
  #sticky-checkout-button {
    @include breakpoint($medium-up) {
      display: none;
    }
  }
  .checkout-panel--bottom-viewcart-buttons {
    @include breakpoint($medium-up) {
      display: none;
    }
  }
}

// Quick links
.overlay-addclass-generic-helplinks-overlay {
  width: 630px;
  height: 550px;
  #cboxContent {
    .cs-page {
      &__header {
        display: none;
      }
      &__navigation {
        display: none;
      }
      &__content {
        padding-left: 17px;
        padding-right: 17px;
        width: 100%;
      }
    }
    .sidebar-page {
      border-bottom: 0px;
      &__content {
        .expando-block {
          margin: 0;
        }
      }
    }
  }
  .page-footer {
    display: none;
  }
  .page-wrapper {
    padding-top: 0px;
  }
}
/* Confirm delete popup */
.confirm-delete {
  &__link {
    text-transform: capitalize;
    cursor: pointer;
  }
}

.delete-confirm-overlay {
  padding: 20px;
  .address-delete {
    padding: 20px;
  }
}

body.checkout-optimize {
  .page-wrapper {
    &--with-offer-banner {
      .checkout--confirmation-page {
        padding-top: 50px;
        @include breakpoint($medium-up) {
          padding-top: 0;
        }
      }
    }
  }
  .line-divider {
    border-bottom: 1px solid $color-bg-gray;
    margin: 20px 0;
    &.both-none {
      margin: 0;
    }
    &.top-none {
      margin-top: 0;
    }
    &.top-half {
      margin-top: 10px;
    }
    &.bottom-none {
      margin-bottom: 0;
    }
    &.bottom-half {
      margin-bottom: 10px;
    }
  }
  .link_item {
    &--call,
    &--chat,
    &--email {
      display: inline-block;
      padding: 0 20px;
      text-align: center;
      &__link {
        text-decoration: none;
        line-height: 1.75em;
        &:hover {
          text-decoration: none;
          color: $color-black;
        }
      }
      .icon {
        font-size: 24px;
      }
    }
    &--call {
      .icon {
        font-weight: bold;
      }
    }
    &--chat {
      line-height: 1.75em;
      cursor: pointer;
    }
  }
  .check-circle-icon {
    height: 35px;
    padding: 5px 20px;
    margin-bottom: 14px;
  }
  .link_items__contact {
    display: flex;
    justify-content: center;
    padding: 0.5em 0;
  }
  span.mobile-hidden {
    display: none;
    @include breakpoint($medium-up) {
      display: inline-block;
    }
  }
  .site-header__section--utilities {
    margin-top: 0;
  }
  .shipping-address-display-content,
  .gift-options-display-content,
  .payment-display-content {
    p {
      font-size: 14px;
      margin-top: 10px;
    }
  }
  .selectBox.error {
    .selectBox-label {
      color: $color-red;
    }
  }
  .selectBox-label {
    color: $color-black;
    font-family: $font--avenir-roman;
    &:after {
      @include breakpoint($medium-up) {
        background: $color-black;
        color: $color-white;
      }
    }
  }
  .site-footer {
    .selectBox-label {
      color: $color-white;
    }
  }
  .checkout {
    @include breakpoint($medium-up) {
      padding: 0;
    }
    max-width: 100%;
    &__header {
      border-bottom: none;
      height: 50px;
      background-color: $color-bg-gray;
      text-align: center;
      @include breakpoint($medium-up) {
        height: 90px;
      }
    }
    &__heading {
      &__count {
        font-family: $font--avenir-roman;
      }
    }
    &__container {
      margin: 0 10px;
      max-width: $max-width;
      @include breakpoint($medium-up) {
        margin: 0 auto;
      }
      @include breakpoint($medium-down) {
        padding-bottom: 20px;
      }
    }
    &.checkout-single-page {
      .checkout-panel {
        &.finished,
        &.collapsed {
          @include breakpoint($medium-up) {
            opacity: 0.5;
            padding: 0.5em 1em;
          }
        }
        &.collapsed {
          .checkout-panel__header {
            margin-bottom: 1em;
          }
        }
      }
    }
    .checkout-progress-bar {
      &__list-item {
        @include icon('radio', before);
        text-transform: lowercase;
        &:before {
          font-size: 19px;
          width: 19px;
          display: block;
          color: $color-black;
          background: $color-white;
          margin: auto;
          margin-bottom: 5px;
        }
        &:after {
          content: url(/media/export/cms/checkout/checkout-mobile-line.svg);
          position: relative;
          left: -50%;
          top: -39px;
          z-index: -1;
          background: none;
        }
        &:first-child:after {
          content: none;
        }
        &--complete {
          @include icon('radio--checked', before);
          font-weight: normal;
        }
      }
    }
    &__content {
      .checkout-panel--viewcart .cart-items .error {
        @include breakpoint($large-up) {
          text-align: #{$ldirection};
          position: relative;
          left: 18%;
          width: 82%;
        }
      }
      @include breakpoint($medium-up) {
        border-#{$rdirection}: none;
        padding: 10px;
      }
      .gift-message__container {
        .gift-message__title {
          margin-bottom: 0;
        }
      }
      fieldset {
        &.fs {
          min-width: 100%;
        }
      }
      .payment-display-content {
        &__heading {
          @include breakpoint($large-up) {
            font-size: 18px;
          }
        }
        .cc {
          display: none;
          float: #{$ldirection};
          &.visa.Visa {
            display: inline-block;
          }
          &.mastercard.MasterCard {
            display: inline-block;
          }
          &.amex.American.Express {
            display: inline-block;
          }
          &.discover.Discover {
            display: inline-block;
          }
        }
        .cc-details {
          display: inline-block;
        }
      }
      .warning-message {
        font-weight: normal;
      }
    } // &__content
    &__sidebar {
      background-color: $color-white;
      @include breakpoint($medium-down) {
        margin-top: 20px;
      }
      @include breakpoint($medium-up) {
        padding: 10px;
      }
      .checkout-button,
      .apple-pay-button-container {
        display: none;
        @include breakpoint($medium-up) {
          display: block;
        }
      }
      .checkout-panel--checkout-button {
        .checkout-button {
          @include breakpoint($large-up) {
            padding: 0;
          }
        }
      }
      .checkout-panel__content {
        padding: 0.5em;
      }
      .checkout-panel__header {
        .edit {
          line-height: 22px;
          margin-#{$rdirection}: 10px;
          margin-top: 0;
        }
      }
      .checkout-panel__heading {
        font-size: 18px;
        text-transform: uppercase;
        margin-bottom: 0.5em;
      }
      .checkout-panel--offer-code,
      .checkout-panel--links {
        padding: 0.5em;
        border-bottom: none;
        background-color: $color-bg-gray;
        margin-bottom: 20px;
        .collapsible-block {
          padding: 0;
          border-bottom: none;
        }
        .checkout-panel__heading {
          margin-bottom: 0;
        }
        .collapsible-block__content {
          padding: 0;
        }
      }
      .checkout-panel--viewcart {
        background-color: $color-bg-gray;
        margin-bottom: 20px;
        .checkout-panel__header {
          text-align: center;
          border-bottom: 2px solid $color-white;
        }
        .cart-items__item {
          padding: 1em;
          margin: 0;
          border-bottom: 2px solid $color-white;
          &--thumb {
            width: 33%;
            padding-#{$rdirection}: 10px;
          }
          &--desc {
            width: 67%;
          }
          &--price {
            @include breakpoint($large-up) {
              width: 100%;
            }
          }
          &--qty,
          &--total {
            @include breakpoint($large-up) {
              width: 67%;
            }
          }
          &--qty {
            margin-top: 0;
          }
        }
      }
    }
    &--confirmation-page {
      font-size: 16px;
    }
    &__footer {
      .warning-message {
        margin-bottom: 1em;
        color: $color-red;
        font-size: 1.2em;
        font-weight: normal;
        text-align: center;
      }
    }
  } // .checkout

  &.checkout-opc {
    &.active-panel-signin {
      .site-footer,
      .gnav-util--search,
      .gnav-util--cart {
        display: block;
      }
      .gnav-util--locator {
        display: block;
        @include breakpoint($medium-up) {
          display: none;
        }
      }
      .site-header__section--nav {
        @include breakpoint($medium-up) {
          display: block;
        }
      }
      .page-wrapper {
        height: 30px;
        @include breakpoint($medium-up) {
          padding-top: 95px;
        }
        &--with-offer-banner {
          @include breakpoint($medium-up) {
            padding-top: 115px;
          }
        }
      }
      .site-header__section--branding {
        @include breakpoint($large-down) {
          max-width: 50%;
          position: absolute;
          left: 40px;
        }
      }
      .site-header__logo__container {
        @include breakpoint($large-down) {
          float: #{$ldirection};
          margin-#{$ldirection}: 0;
          margin-#{$rdirection}: 0;
        }
        @include breakpoint($iphone6-up) {
          margin-#{$ldirection}: 10px;
        }
      }
      .site-footer__section__email,
      .site-footer__section__quicklink,
      .site-footer__section__collection,
      .footer__item__primary__menu--l1,
      .footer__item__primary__menu--l2,
      .footer__item__social {
        display: block;
      }
      .checkout-single-page {
        padding: 0;
      }
    }
    .gnav-util--search,
    .gnav-util--cart,
    .gnav-util--locator {
      display: none;
    }
    .site-header__section--nav {
      @include breakpoint($medium-up) {
        display: none;
      }
    }
    .page-wrapper {
      @include breakpoint($medium-up) {
        padding-top: 65px;
      }
      &--with-offer-banner {
        @include breakpoint($medium-up) {
          padding-top: 115px;
        }
      }
    }
    .site-header__logo__container {
      @include breakpoint($large-down) {
        float: none;
        margin-#{$ldirection}: auto;
        margin-#{$rdirection}: auto;
      }
    }
    .site-footer__section__email,
    .site-footer__section__quicklink,
    .footer__item__primary__menu--l1,
    .footer__item__primary__menu--l2,
    .footer__item__social {
      display: none;
    }
    .site-footer__section__collection {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
      }
    }
    .checkout-single-page {
      padding: 0 0 45px 0;
      @include breakpoint($medium-up) {
        padding: 0;
      }
    }
  } // checkout-opc

  &.active-panel-registration {
    .gnav-util--search,
    .gnav-util--cart,
    .gnav-util--locator {
      display: none;
    }
    .site-header__section--nav {
      @include breakpoint($medium-up) {
        display: none;
      }
    }
    .page-wrapper {
      @include breakpoint($medium-up) {
        padding-top: 65px;
      }
    }
    .site-header__section--branding {
      @include breakpoint($large-down) {
        width: 80%;
        margin: auto;
        position: relative;
        left: 0;
      }
    }
    .site-header__logo__container {
      @include breakpoint($large-down) {
        float: none;
        margin-#{$ldirection}: auto;
        margin-#{$rdirection}: auto;
      }
    }
    .site-footer__section__email,
    .site-footer__section__quicklink,
    .footer__item__primary__menu--l1,
    .footer__item__primary__menu--l2,
    .footer__item__social {
      display: none;
    }
    .site-footer__section__collection {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
      }
    }
  }
  .checkout-panel {
    padding: 0;
    border-bottom: 1px solid $color-bg-gray;
    &:last-child {
      @include breakpoint($medium-up) {
        border-bottom-width: 0;
      }
    }
    .links-content {
      a {
        font-size: 16px;
      }
      text-align: center;
    }
    @include breakpoint($medium-down) {
      .checkout-panel--email-and-sms-promotions,
      .giftwrap__is-gift {
        text-align: center;
        input[type='checkbox'] ~ label::before {
          float: none;
        }
        input[type='checkbox'] ~ label .label-content {
          display: inline;
        }
      }
    }
    .checkout-panel {
      border: none;
    }
    &--viewcart {
      border-bottom: 1px solid $color-bg-gray;
      .cart-items {
        &__header {
          border-bottom: 1px solid $color-bg-gray;
          &--item {
            text-transform: uppercase;
            border-bottom: none;
          }
          &--products {
            width: 50%;
          }
          &--price {
            width: 16%;
            text-align: #{$ldirection};
          }
          &--qty {
            width: 18%;
            text-align: #{$ldirection};
            margin-top: 0;
          }
          &--total {
            width: 12%;
            float: #{$ldirection};
            text-align: #{$ldirection};
          }
        }
        &__item {
          padding: 1.25em 0;
          margin: 0;
          &:nth-last-child(1) {
            border-bottom: none;
          }
          &--thumb {
            width: 18%;
            padding-#{$rdirection}: 10px;
            @include breakpoint($medium-down) {
              width: 25%;
            }
          }
          &--desc {
            width: 32%;
            line-height: 1.5em;
            .cart--item__product-name {
              font-family: $font--avenir-medium;
            }
            @include breakpoint($medium-down) {
              width: 65%;
            }
          }
          &--price {
            width: 16%;
            text-align: #{$ldirection};
            @include breakpoint($medium-down) {
              width: 100%;
            }
            font-family: $font--avenir-heavy;
          }
          &--discount {
            font-family: $font--avenir-light;
          }
          &--qty {
            width: 18%;
            text-align: #{$ldirection};
            &__label {
              margin-top: 0;
              display: inline-block;
            }
            @include breakpoint($small-down) {
              width: auto;
              margin-#{$ldirection}: auto;
            }
            a.selectBox {
              @include breakpoint($landscape-up) {
                text-align: #{$ldirection};
              }
            }
          }
          &--total {
            float: #{$ldirection};
            font-family: $font--avenir-heavy;
            text-align: #{$ldirection};
          }
          &--replenishment {
            width: auto;
            margin-#{$ldirection}: 0;
          }
          &--error--out-of-stock {
            @include breakpoint($medium-up) {
              width: 82%;
            }
          }
        }
        .error {
          margin-top: 10px;
          color: $color-red;
          font-family: $font--avenir-book;
          font-size: 14px;
          text-align: center;
          padding: 10px 0;
        }
        .cart--item__remove-form {
          text-align: #{$rdirection};
          a {
            color: $color-gray;
            font-size: 20px;
            text-decoration: none;
          }
        }
      }
      .signin-to-see-cart {
        padding-bottom: 20px;
      }
    }
    &--promo {
      border-bottom: none;
      @include breakpoint($medium-up) {
        padding-bottom: 10px;
      }
      .messages {
        padding: 0;
      }
      .image-tout {
        padding: 10px 0;
      }
    }
    &--promo-bca {
      border-bottom: 1px solid $color-bg-gray;
      padding: 1em 0;
      @include breakpoint($landscape-up) {
        border-bottom: 1px solid $color-bg-gray;
      }
      .promo-bca-content {
        &__thumb {
          width: 18%;
          float: #{$ldirection};
          padding-#{$rdirection}: 10px;
          @include breakpoint($medium-down) {
            width: 25%;
          }
        }
        &__desc {
          width: 82%;
          float: #{$ldirection};
          @include breakpoint($medium-down) {
            width: 75%;
            padding-#{$rdirection}: 0;
            padding-bottom: 0;
          }
        }
        &__header {
          @include header--small;
          font-family: $font--avenir-medium;
        }
        &__note {
          margin: 0;
        }
        &__button {
          width: auto;
          clear: both;
          display: block;
        }
        &__description {
          width: auto;
          margin: 0;
        }
        &__logo {
          max-width: 120px;
          width: 100%;
        }
      }
      .donation-amounts {
        margin: 10px 0;
      }
    }
    &--recommended-products {
      @include swap_direction(padding, 20px 0 30px);
      border-bottom: none;
      .checkout-panel__heading {
        font-size: 15px;
        text-transform: uppercase;
      }
      .recommended-products-content {
        @include swap_direction(margin, 20px 0);
        &__items {
          .recommended-item {
            position: relative;
            float: #{$ldirection};
            width: 50%;
            margin-bottom: 25px;
            @include breakpoint($iphone6plus-up) {
              width: 33%;
            }
            &__description {
              @include swap_direction(padding, 10px);
              min-height: 114px;
              text-align: center;
              line-height: 1.2;
              a {
                text-decoration: none;
              }
              .product-subname {
                @include swap_direction(padding, 8px 0);
                font-size: 13px;
              }
              .product-name,
              .formatted-price {
                font-family: $font--avenir-heavy;
              }
            }
            &__thumb {
              min-height: 197px;
              @include breakpoint($iphone6plus-up) {
                min-height: 275px;
              }
            }
            &__button {
              height: 45px;
              form {
                position: absolute;
                bottom: 0;
                #{$ldirection}: 50%;
                transform: translateX(-50%);
              }
            }
          }
        }
      }
    }
    &--order-summary {
      margin-bottom: 20px;
      background-color: $color-bg-gray;
      .checkout-panel__header {
        text-align: center;
      }
      .ship-method {
        a.selectBox {
          .selectBox-label {
            color: $color-black;
            background: $color-white;
            padding-#{$ldirection}: 15px;
          }
        }
      }
      .shipping-methods {
        width: 100%;
        clear: both;
        margin-bottom: 0.5em;
      }
    }
    &--order-total {
      margin-bottom: 20px;
      background-color: $color-bg-gray;
      .checkout-panel__header {
        padding: 0.5em;
      }
      .label {
        float: #{$ldirection};
        width: 65%;
        clear: both;
        font-family: $font--avenir-heavy;
        font-size: 16px;
        font-weight: normal;
      }
      .value {
        float: #{$rdirection};
        width: 35%;
        text-align: #{$rdirection};
        font-family: $font--avenir-heavy;
        font-size: 16px;
        font-weight: normal;
      }
      .summary-message {
        height: 75px;
        padding-top: 10px;
      }
    }
    &--sign-in-main {
      .form-item {
        @include breakpoint($landscape-up) {
          &.email-address input,
          &.password input {
            width: 100%;
          }
        }
        &.submit {
          clear: both;
        }
      }
    }
    &--return-user,
    &--new-account {
      input[type='email'],
      input[type='password'] {
        border: 2px solid $color-black;
      }
    }
    &--new-account,
    &--return-user,
    &--shipping,
    &--payment,
    &--review {
      @include breakpoint($medium-down) {
        .checkout-panel__header {
          text-align: center;
        }
        .checkout-panel__heading {
          text-transform: uppercase;
        }
      }
    }
    &--shipping {
      .checkout-panel__header {
        p {
          margin: 0;
          font-weight: normal;
        }
      }
    }
    &--shipping-edit-address {
      .checkout-panel__content {
        p {
          margin: 0;
        }
      }
      .address-form {
        .first-name,
        .last-name {
          width: 50%;
          padding: 0 1em 1em 0;
          float: #{$ldirection};
        }
        .google_autocomplete,
        .address1,
        .address2 {
          padding: 0 1em 1em 0;
        }
        &__city-state-zip {
          .city,
          .state,
          .postal-code,
          .phone-1 {
            padding: 0 1em 1em 0;
            float: #{$ldirection};
          }
          .city {
            width: 60%;
            @include breakpoint($landscape-up) {
              width: 30%;
            }
          }
          .postal-code {
            width: 50%;
            @include breakpoint($landscape-up) {
              width: 25%;
            }
          }
          .state {
            width: 40%;
            clear: none;
            .select-box {
              width: 40%;
            }
            @include breakpoint($landscape-up) {
              width: 20%;
            }
          }
          .phone-1 {
            width: 50%;
            @include breakpoint($landscape-up) {
              width: 25%;
            }
          }
        }
      }
      .default-shipping {
        label {
          padding-#{$ldirection}: 0;
        }
      }
    }
    &--payment {
      &.panel-substate-paypal {
        div.payment-container {
          display: block;
        }
      }
      .checkout_giftcard,
      .payment-form {
        @include breakpoint($large-up) {
          width: 75%;
        }
      }
      .form-item {
        input[type='text'],
        input[type='password'],
        input[type='textfield'],
        input[type='email'],
        input[type='tel'],
        textarea,
        select {
          &:disabled {
            background: $color-light-gray;
          }
        }
        .selectBox-disabled {
          .selectBox-label {
            background: $color-light-gray;
          }
        }
        .giftcard-check-balance {
          color: $color-black;
          background: $color-white;
          width: 49%;
          padding-#{$ldirection}: 5px;
          padding-#{$rdirection}: 5px;
          float: #{$ldirection};
        }
        .giftcard-apply {
          width: 49%;
          float: #{$rdirection};
        }
      }
      .card-number {
        width: 100%;
        input {
          height: 45px;
        }
      }
      .expires-month,
      .expires-year {
        width: 38%;
        padding-#{$rdirection}: 0.5em;
        float: #{$ldirection};
      }
      .cvv {
        width: 24%;
        float: #{$ldirection};
        input {
          height: 45px;
          width: 100%;
        }
      }
    }
    &--review {
      &.active {
        border-bottom: none;
      }
      .address-fullname {
        font-weight: bold;
      }
    }
    &--sign-in-confirmation {
      .form-item {
        margin-bottom: 1em;
      }
    }
    &--confirmation {
      .link {
        text-decoration: underline;
        border-bottom: none;
      }
    }
    #offer_code {
      .form-submit {
        margin-#{$ldirection}: 0;
        height: 40px; // Matches input field height
        padding: 6px 5px 5px 5px;
        @include breakpoint($medium-up) {
          border: 2px solid $color-black;
        }
      }
      fieldset {
        .form-item {
          width: 50%;
          padding-#{$rdirection}: 0;
          &.offer_code_input {
            width: 75%;
            float: #{$ldirection};
          }
          &.offer_code_submit {
            width: 25%;
            float: #{$ldirection};
          }
          input[type='text'] {
            height: 40px;
            border: 1px solid $color-black;
            & + label {
              height: 40px;
              margin-top: -41px;
              font-size: 16px;
            }
          }
        }
      }
    }
    .billing-edit-address-header {
      @include breakpoint($medium-down) {
        text-align: center;
      }
    }
    &__header {
      .icon--utility_check-circle {
        margin-#{$rdirection}: 20px;
        font-size: 28px;
        line-height: 1.25;
        margin-bottom: 0.5em;
      }
      .edit {
        float: #{$rdirection};
        text-transform: lowercase;
        font-weight: normal;
        line-height: 35px;
      }
    }
    &__header-review {
      .edit {
        float: #{$rdirection};
        text-transform: lowercase;
        font-weight: normal;
        line-height: 24px;
        @include breakpoint($large-up) {
          float: none;
          margin-#{$ldirection}: 20px;
        }
      }
    }
    &__heading-review {
      @include header--medium;
      display: inline-block;
      margin: 0;
      padding: 0;
      text-transform: none;
      font-family: $font--avenir-heavy;
      @include breakpoint($large-up) {
        font-size: 19px;
      }
    }
    &__heading {
      margin-bottom: 0;
    }
    .continue-button-wrapper {
      @include breakpoint($large-up) {
        margin: 20px 0;
      }
    }
    .messages {
      font-size: 14px;
      font-family: $font--avenir-book;
      text-align: center;
    }
    .warning-message {
      font-weight: normal;
    }
    .place-order-heading {
      font-size: 19px;
      color: $color-red;
      font-family: $font--avenir-medium;
      @include breakpoint($medium-up) {
        font-size: 28px;
      }
    }
  }
  .offer-code-content {
    &__current-offers {
      text-decoration: underline;
      float: #{$ldirection};
      cursor: pointer;
    }
    &__offer-limit {
      font-size: 13px;
      color: $color-gray;
      float: #{$rdirection};
    }
  }
  .payment-giftcard {
    margin-top: 20px;
    .checkout__panel--heading {
      margin-bottom: 0;
    }
  }
  .salon_program_container {
    .checkout-panel__heading {
      @include breakpoint($medium-down) {
        text-align: center;
        width: 100%;
      }
    }
    .salon {
      @include breakpoint($medium-down) {
        text-align: center;
        input[type='checkbox'] ~ label::before {
          float: none;
        }
      }
      .salon_container.radio {
        margin-bottom: 20px;
      }
      #salon_container_wrap {
        margin-top: 20px;
        .salon_container.select {
          @include breakpoint($large-up) {
            display: inline-block;
            width: 45%;
          }
        }
        .or_text {
          margin: 20px;
          font-size: 18px;
          font-family: $font--avenir-heavy;
          text-transform: uppercase;
          @include breakpoint($large-up) {
            display: inline-block;
            margin: 10px 20px 11px 20px;
          }
        }
        #select_salon_link {
          text-decoration: none;
          width: 100%;
          @include breakpoint($large-up) {
            width: 45%;
            float: #{$rdirection};
            height: 45px;
            line-height: 34px;
          }
        }
        .stylist_container {
          margin: 20px 0;
          .salon_stylist_name {
            text-align: #{$ldirection};
            input {
              width: 100%;
              @include breakpoint($medium-up) {
                width: 50%;
              }
            }
          }
          .checkout-panel__heading {
            text-transform: none;
            font-size: 19px;
          }
        }
        &.salon_container__wrap {
          .select {
            .selectbox {
              font-size: 16px;
            }
          }
          .salon-submit {
            width: 100%;
            margin-bottom: 20px;
            @include breakpoint($large-up) {
              width: auto;
              height: 45px;
              line-height: 2.3;
              margin-top: 10px;
            }
          }
        }
      }
      .sub-title {
        font-size: 14px;
      }
    }
    .error {
      color: $color-red;
    }
    .success--message {
      color: $color-green;
      padding-top: 10px;
      font-size: 16px;
    }
  }
  .salon-search {
    &-locator {
      .form-item {
        input,
        select {
          border-width: 2px;
        }
      }
      .or_text {
        font-size: 16px;
        font-family: $font--avenir-heavy;
        @include breakpoint($landscape-up) {
          width: 100%;
        }
      }
    }
    .form-item {
      &.salon-search-locator__city {
        width: 60%;
        float: #{$ldirection};
        input[type='text'] {
          height: 45px;
        }
      }
      &.salon-search-locator__state {
        width: 38%;
        float: #{$rdirection};
        padding-#{$rdirection}: 0;
      }
      &.salon-search-locator__zipcode {
        width: 100%;
        padding-#{$rdirection}: 0;
      }
    }
    &__submit {
      width: 100%;
      height: 45px;
      @include breakpoint($medium-up) {
        line-height: 32px;
      }
    }
    &__location {
      .checkout-panel__heading {
        text-transform: uppercase;
      }
      .selectBox-label {
        color: $color-black;
        font-family: $font--avenir-roman;
      }
    }
  }
  .checkout-buttons-content {
    padding-top: 20px;
  }
  .checkbox--paypal {
    label {
      line-height: 1.75em;
    }
  }
  .do-not-save-payment {
    clear: both;
  }
  .billing-edit-address-header {
    padding-bottom: 1em;
  }
  .billing_address_radio {
    margin: 1em 0;
    font-weight: bold;
    .icon {
      margin-#{$rdirection}: 10px;
    }
    &:hover {
      text-decoration: none;
      color: $color-black;
    }
  }
  .billing_address_display {
    margin-#{$ldirection}: 20px;
  }
  /* SAMPLES PAGE */
  .samples-page {
    .product-size {
      font-size: 14px;
    }
    .details {
      text-align: center;
    }
    .sample-select-button {
      width: 100%;
      padding: 12px 6px 11px 6px;
      &.selected {
        color: $color-black;
        background: $color-white;
      }
    }
    ul.product-list .product {
      padding: 0.5em;
      @include breakpoint($landscape-up) {
        width: 25%;
      }
      .skus .sku.disabled {
        .sample-select-button {
          color: $color-white;
          background: $color-gray;
          border: 2px solid $color-gray;
        }
      }
    }
    .samples-content {
      padding: 0 10px;
      &__button {
        &--checkout {
          @include breakpoint($large-up) {
            margin-bottom: 20px;
            margin-#{$ldirection}: 0;
          }
        }
      }
      &__header {
        text-align: center;
      }
    }
  }
  .gift-options-content {
    margin-bottom: 20px;
    .card-message {
      padding: 0 1em 1em 0;
      clear: both;
      &-to,
      &-from {
        width: 50%;
        padding: 0 1em 1em 0;
        float: #{$ldirection};
      }
      .gift-max-char {
        margin: 0.25em 1em;
        font-size: 13px;
      }
    }
  }
  .go-shopping {
    margin: 10px 0;
  }
  .cart-item {
    padding: 1em 0;
    border-bottom: 1px solid $color-bg-gray;
  }
  .product-replenishment-select {
    &__option {
      label {
        font-size: 16px;
      }
    }
  }
  .opt-hidden {
    display: none;
  }
  .show-opt-only {
    display: block;
  }
}

.show-opt-only {
  display: none;
}

.active-panel-wp_samples {
  .page-wrapper {
    margin-bottom: 75px;
    @include breakpoint($medium-up) {
      margin-bottom: 0;
    }
  }
}
