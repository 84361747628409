.terms-expandable {
  .collapsible-block {
    padding: 1em 0 0 0;
    border-bottom: #000 dashed 1px;
    border-top: #000 dashed 1px;
    .collapsible-block__title {
      margin-left: 1em;
    }
    .collapsible-block__content-item {
      margin-left: 1em;
      ul,
      li {
        list-style: disc;
        margin-left: 1em;
      }
    }
  }
}

.basic-formatter__item {
  .margin-padding-bottom {
    border-bottom: 1px solid #ebebeb;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 0 45px 0;
  }
}

@media (max-width: 850px) {
  .basic-formatter__item {
    .margin-padding-bottom {
      padding: 0 0 30px 0;
    }
  }
}
