// Expose stars on hover - MPP page
$show-reviews-on-hover: true !default;

@import '../../bumble_base/scss/bumble-base-pc';
@import '../../bumble_base/scss/sections/checkout/checkout-shared';
@import 'sections/pc/_account';
@import 'sections/pc/_checkout';
@import 'sections/pc/_applepay';
@import 'sections/_giftcards';
@import 'sections/_clickcollect';
@import 'sections/components/_all';
@import 'sections/components/_header';
@import 'bt/_bt';
@import 'sections/social_login';

.site-header__section--banner__utility a {
  float: right;
}

.site-header__contact {
  @include breakpoint($medium-up) {
    padding-right: 10px;
  }
}
