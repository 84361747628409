$medium-landscape-down: max-width 1024px;
$medium-landscape-only: (width 768px 1024px) (orientation landscape); // iPad landscape

//.adpl label[data-required]:before { content: "\E02D"; }

.perlgem {
  .page-wrapper {
    .site-header {
      left: 0px;
      top: 0px;
    }
  }
  &.checkout {
    padding: 0;
    max-width: 100%;
    .checkout-panel {
      .loading {
        /** Bugfix UKSITEDEV-2300 - double loading icon */
        background: white;
      }
    }
    .section-sms-promotions {
      .sms-mobile {
        &-prefix {
          float: left;
          border: 1px solid $color-black;
          height: 46px;
          line-height: 46px;
          padding: 0 5px 0 9px;
          margin-right: 1%;
        }
      }
    }
    #cboxLoadedContent {
      .cs-page {
        &__content {
          width: 100%;
        }
        &__contacts {
          display: none;
        }
        &__navigation {
          display: none;
        }
      }
    }
  }
}

// !important is used below to override the inline styles from 3rd party content
.afterpay-paragraph {
  .afterpay-text1 {
    margin-#{$rdirection}: 4px;
  }
  .afterpay-logo-link {
    vertical-align: text-bottom;
  }
  .afterpay-link {
    background: url(/media/images/checkout/utillity_information.svg) no-repeat;
    width: 20px;
    height: 20px;
    text-decoration: none !important;
    color: transparent !important;
    vertical-align: middle;
    margin-#{$ldirection}: 6px;
  }
}

#viewcart {
  .checkout {
    &__content {
      &.left {
        @include breakpoint($medium-only) {
          width: 100%;
        }
      }
      @extend .checkout-panel;
      .checkout-panel {
        margin-bottom: 2em;
        /* Remove if not needed - check after demo */
        &__header {
          h2 {
            border-bottom: 1px solid #ebebeb;
            width: 100%;
            font-weight: normal;
            font-size: 16px;
            padding: 0.8em 0;
          }
        }
        &--viewcart {
          .cart-items__item {
            &--total {
              @include breakpoint($small-down) {
                bottom: 0;
                margin: 0;
                float: right;
                width: auto;
                padding-left: 0px;
                padding-right: 10px;
                &.free-sample-item {
                  display: block;
                }
              }
            }
            &--thumb {
              @include breakpoint($small-down) {
                height: 150px;
                width: 80px;
              }
            }
            &--desc {
              @include breakpoint($small-down) {
                width: 60%;
              }
              .cart--item__remove-form {
                @include breakpoint($small-down) {
                  margin-bottom: 10%;
                }
              }
            }
            &--qty {
              @include breakpoint($small-down) {
                margin: 0;
                margin-top: -10px;
                text-align: center;
                margin-left: 10px;
                width: 20%;
              }
              @include breakpoint($small-down) {
                margin-top: -10px;
                padding: 0;
              }
              .selectbox {
                @include breakpoint($small-down) {
                  width: auto;
                }
              }
            }
            &--price {
              .product-price {
                @include breakpoint($medium-up) {
                  display: block;
                }
              }
              .sale-price {
                margin-right: 0;
              }
              @include breakpoint($small-down) {
                clear: both;
                float: left;
                padding: 0;
                margin: 0;
                width: 90px;
                text-align: center;
              }
              min-height: 1px;
            }
          }
        }
      }
      #checkout_shipmethod {
        .ship-method-group-label {
          .shipment-option {
            @include breakpoint($small-down) {
              width: 100%;
              display: block;
              margin-bottom: 5%;
            }
            label {
              display: inline;
              margin-right: 10px;
            }
          }
        }
        .ship-method-collection-group,
        .ship-method-home-group {
          margin: 3% 0;
          select {
            width: 65%;
          }
        }
      }
      .checkout-panel--bottom-section {
        .total-container {
          padding-top: 10px;
        }
        .afterpay {
          width: 100%;
          text-align: #{$rdirection};
          img {
            @include swap_direction(margin, 2px 2px 0 1px);
            vertical-align: bottom;
          }
          .afterpay-learnmore {
            vertical-align: middle;
            .info-icon {
              width: 21px;
              height: 20px;
              vertical-align: sub;
              margin-#{$ldirection}: 2px;
              display: inline-block;
              cursor: pointer;
            }
          }
        }
        .total-container__content {
          .order-summary-content__label--total {
            float: left;
            width: 65%;
            margin-bottom: 0.5em;
            text-align: right;
            font-weight: bold;
          }
          .order-summary-content__value--total {
            float: right;
            width: 35%;
            margin-bottom: 0.5em;
            text-align: right;
            font-weight: bold;
          }
        }
        .bottom-viewcart-buttons {
          .viewcart-buttons-panel {
            margin-top: 5%;
            .continue-shopping {
              float: left;
            }
            .checkout-buttons-content__container {
              float: right;
              width: 70%;
              text-align: center;
              @include breakpoint($small-down) {
                width: 100%;
              }
              .checkout-buttons-content__item {
                &.paypal-checkout {
                  display: inline-block;
                  width: 35%;
                  border: 1px solid #000;
                  text-align: center;
                  img {
                    margin: 4%;
                    @include breakpoint($small-down) {
                      margin: 1%;
                    }
                  }
                }
                @include breakpoint($medium-landscape-only) {
                  margin: 0 20px 0 0;
                }
                &.disabled {
                  img {
                    opacity: 0.2;
                    filter: alpha(opacity=20);
                  }
                }
              }
              .continue-checkout {
                width: 35%;
              }
              .or {
                float: right;
              }
            }
          }
        }
      }
      .donations {
        overflow: visible;
        &.panel {
          #charity-header {
            margin-bottom: 12px;
            border-bottom: 1px solid #ebebeb;
            width: 100%;
            font-weight: normal;
            font-size: 16px;
            padding: 0.8em 0;
          }
          .donation_info_panel {
            float: left;
            margin-bottom: 1em;
            .donation_logo,
            .donation_message {
              @include mobile-hidden;
            }
            .donation_logo_mobile,
            .donation_message_mobile {
              @include pc-hidden;
              font-size: 90%;
            }
            .donation_logo,
            .donation_logo_mobile {
              height: 100%;
              float: left;
              margin-right: 2em;
            }
            .donation_message {
              width: 69%;
              float: right;
            }
          }
          .content {
            padding-left: 0;
            .donation_amounts_panel {
              clear: both;
              margin-top: 15px;
              span#select-amount {
                @include mobile-hidden;
                float: left;
                width: 30%;
                font-weight: bold;
                font-size: 16px;
              }
              span#select-amount-mobile {
                @include pc-hidden;
                float: left;
                width: 30%;
              }
              .donation-group {
                display: inline;
                font-size: 16px;
                width: 70%;
                label {
                  display: inline;
                  margin-right: 10px;
                }
              }
            }
          }
          .donationMessages {
            &.messages {
              margin-left: 10px;
              .single-message {
                margin-left: 0;
                margin-top: 0;
              }
            }
          }
        }
      }
      .checkout-panel--offer-code {
        .offer-code-content {
          &__input {
            margin-bottom: 0px;
          }
        }
      }
    }
    &__sidebar {
      .checkout-panel--offer-code {
        .offer-code-content {
          &__input {
            width: 100%;
          }
          &__submit {
            width: 100%;
          }
          &__view--all--offers {
            margin: 6px 0 0px 0;
          }
        }
      }
    }
    &-panel--recommended-products {
      .recommended-products-content {
        .recommended-products-content__items {
          .recommended-items {
            width: 33%;
            float: left;
          }
        }
      }
    }
  }
}

#review-panel {
  .review-edit-content {
    .billing-option {
      float: left;
      margin-right: 2em;
      margin-bottom: 1em;
    }
    .delivery-address {
      clear: both;
    }
    .terms_condition {
      margin-top: 2em;
    }
    .new-address {
      clear: both;
    }
    .payment-options {
      width: 88%;
      @include breakpoint($mediumPC-up) {
        width: 77%;
      }
    }
    .creditcard-option {
      @include swap_direction(padding, 25px 0 10px);
      border-top: 1px solid #{$color-black};
    }
    label {
      margin-#{$ldirection}: 15px;
    }
    .payinfo-text {
      font-family: $font--avenir-heavy;
      font-size: 19px;
    }
    .cc {
      margin-#{$ldirection}: 5px;
      span {
        width: 100%;
        float: #{$ldirection};
        padding-top: 10px;
      }
    }
    span {
      .creditcard-media {
        display: none;
      }
      &.afterpay-disclaimer {
        float: #{$ldirection};
      }
    }
    .paypal-option {
      @include swap_direction(padding, 25px 0);
      border-top: 1px solid #{$color-black};
      border-bottom: 1px solid #{$color-black};
    }
    .pp {
      span {
        img {
          @include swap_direction(margin, 0 8px);
          width: 70px;
        }
      }
    }
    .afterpay-option {
      @include swap_direction(padding, 25px 0 30px);
      border-bottom: 1px solid #{$color-black};
      margin-bottom: 20px;
      .afterpay {
        display: inline-block;
        width: 50%;
        vertical-align: top;
        margin-top: 3px;
      }
      .afterpay-image {
        @include swap_direction(margin, 1px 3px 0 0);
        width: 112px;
        vertical-align: top;
      }
    }
    .afterpay-learnmore {
      .info-icon {
        width: 21px;
        height: 20px;
        margin-#{$ldirection}: 6px;
        display: inline-block;
      }
    }
    input {
      &.btn {
        &.place-order {
          width: auto;
        }
        &.continue-to-paypal {
          width: auto;
        }
        &.afterpay-button {
          width: 260px;
          text-align: #{$ldirection};
          background: #{$color-black} url(/media/images/checkout/cp-logo-white-transparent.svg) no-repeat 83% 50%;
        }
        &:hover {
          background-color: #{$color-light-black};
        }
      }
    }
    #checkout_payment {
      .payment-form__item {
        width: 100%;
        float: left;
        margin-bottom: 1em;
        .form-item {
          float: left;
          select {
            width: 100%;
          }
          input[type='text'] {
            width: 100%;
          }
          &.block {
            width: 49%;
            float: left;
            margin-bottom: 1em;
            &.card-type {
              label {
                margin-bottom: 1em;
                display: block;
              }
            }
          }
        }
        .section-separation {
          float: left;
          line-height: 55px;
          margin: 20px 6px 0 6px;
          vertical-align: middle;
          font-size: 20px;
        }
        .card-expiration-year {
          margin-top: 28px;
        }
        &.card-expiry-date {
          .card-expiry {
            width: 46%;
            margin-right: 0;
            @include breakpoint($medium-landscape-only) {
              width: 45%;
            }
            label {
              margin-bottom: 5px;
              display: block;
            }
          }
          .card-cvn {
            width: 49%;
          }
        }
      }
      .checkbox {
        width: 100%;
        display: block;
        float: left;
      }
    }
    #checkout_saved_payment {
      .card-cvn {
        width: 50%;
        .what-is-this {
          margin-top: 1%;
        }
      }
    }
    input,
    select {
      &:required:invalid:not(:checked).checkedEmpty {
        border: 1px solid $color-red;
      }
    }
    input[type='checkbox']:required:not(:checked) {
      &.checkedEmpty ~ label {
        color: #e20400;
      }
    }
    .card-cvn {
      input[type='text'] {
        width: 50%;
      }
    }
    .payment-method__item {
      width: 100%;
      .payment-option {
        float: left;
        margin-right: 3em;
        margin-bottom: 1em;
        .related-media {
          span {
            margin-left: 1em;
          }
        }
      }
    }
    form#checkout_billing_panel {
      margin-bottom: 15px;
    }
  }
  .content {
    .gift-cards {
      margin-bottom: 2em;
      .gift-card-container-1 {
        margin-left: 0;
        .gift-card-entry-container {
          .form-item {
            width: 48%;
            float: left;
            padding: 10px;
            input[type='text'] {
              width: 100%;
            }
          }
        }
      }
    }
    .sub-section {
      &.address {
        margin-top: 5%;
        .view-address {
          .select-address {
            .same-as-shipping {
              margin: 2% 0;
            }
          }
        }
        h3 {
          font-size: 19px;
        }
      }
    }
  }
}

.checkout-panel {
  &.checkout-panel--shipping {
    .checkout-panel__header {
      .edit {
        &.link {
          background: none;
          border: none;
          color: #000;
          text-decoration: underline;
          top: 6px;
        }
      }
    }
  }
}

.checkout__sidebar {
  &.column {
    &.right {
      @include breakpoint($medium-only) {
        width: 100%;
      }
      .checkout-panel__content {
        margin-top: 5%;
      }
      .tab-bar {
        margin-right: 0;
        li {
          margin-right: 0;
          a {
            color: $color-white;
            background-color: $color-gray;
            &.active {
              color: $color-white;
              background-color: $color-black;
            }
          }
        }
      }
      .tab-pane {
        opacity: 0;
        height: 0;
        overflow: hidden;
        -webkit-transition: opacity 1s ease;
        -moz-transition: opacity 1s ease;
        -o-transition: opacity 1s ease;
        -ms-transition: opacity 1s ease;
        transition: opacity 1s ease;
        header {
          display: none;
        }
      }
      .tab-pane.active {
        display: block;
        opacity: 1;
        height: auto;
      }
      .tab-switcher {
        @include breakpoint($small-down) {
          display: none;
        }
        .product-list li.product {
          min-height: 0;
          border-bottom: 1px solid #cdcbc9;
          height: auto;
          display: inline-block;
          margin: 10px 0;
          position: relative;
          padding-bottom: 20px;
          width: 100%;
          .product-img {
            float: left;
            min-height: 100px;
            width: 32%;
            img {
              display: inline-grid;
              word-wrap: break-word;
            }
            a.view-larger {
              display: none;
            }
          }
          .shades-list {
            display: none;
          }
          .add-to-cart {
            float: left;
            margin: 10px 0;
          }
          .details {
            margin-left: 0px;
            float: right;
            text-align: left;
            width: 60%;
          }
          ul.skus {
            float: left;
            width: 100%;
            li {
              margin: 10px 0;
              padding: 0;
              width: 100%;
              border: 0;
              .form-item {
                margin-bottom: 0px;
                a.sample-select-button {
                  width: 100%;
                  cursor: pointer;
                  border: 1px solid $color-black;
                  background: $color-white;
                  color: $color-black;
                  &:hover {
                    border: 1px solid $color-black;
                    background: $color-white;
                    color: $color-black;
                  }
                  &.selected {
                    background: $color-black;
                    color: $color-white;
                  }
                }
              }
              &.disabled {
                opacity: 0.5;
              }
            }
          }
        }
        .checkout__panel--heading {
          padding-left: 0px;
        }
        .tab-bar {
          li {
            display: inline-block;
          }
        }
      }
      #order-summary-panel {
        .checkout-panel--viewcart-buttons {
          .total-container {
            padding: 0.5em;
            .total-container__content {
              .total {
                &.order-summary-content__label--total {
                  float: left;
                  width: 65%;
                  clear: both;
                  text-align: left;
                }
                &.order-summary-content__value--total {
                  float: left;
                  width: 35%;
                  text-align: right;
                }
              }
            }
          }
          .afterpay {
            display: none;
          }
        }
      }
      #shipmethod-panel {
        .content {
          padding: 0.5em;
          #checkout_shipmethod {
            .ship-method-group-label {
              .shipment-option {
                label {
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
      }
      .checkout-panel--need-help {
        .need-help-content__item {
          width: 100%;
          float: left;
          margin-bottom: 1em;
        }
      }
    }
  }
}

body#index {
  .checkout-single-page {
    .checkout {
      &__sidebar {
        .checkout-panel--offer-code {
          .offer-code-content {
            &__input {
              width: 100%;
            }
            &__submit {
              width: 100%;
            }
            &__view--all--offers {
              margin: 6px 0 0px 0;
            }
          }
        }
      }
    }
  }
  .checkout-panel--shipping {
    .checkout_shipping_panel {
      .checkout-panel--shipping-edit-address {
        .select-address {
          .new-address {
            .address-form {
              #manual-address-fields {
                width: 75%;
                padding-top: 5%;
              }
            }
          }
          .select-menu {
            select {
              width: 75%;
              margin-bottom: 3%;
            }
          }
          .address-to-use {
            .radio {
              &.ship_group_1 {
                margin-bottom: 3%;
                display: inline-block;
                width: 30%;
                label {
                  display: block;
                }
              }
            }
          }
          .click-and-collect {
            &.new-address-container {
              .local-collection,
              .store-collection {
                margin-top: 3%;
                margin-bottom: 3%;
              }
            }
          }
        }
      }
    }
    .checkout-panel--gift-options-content {
      margin: 2% 0 2% 0;
      h2 {
        font-size: 19px;
      }
      .giftwrap {
        .sub-section {
          clear: both;
          .form-item {
            width: 100%;
          }
        }
      }
    }
    .shipping-gift-options-wrap {
      .shipping-address-display-content {
        display: inline-block;
        float: left;
        width: 50%;
        h3 {
          font-size: 19px;
        }
      }
      .gift-options-display-content {
        display: inline-block;
        float: left;
        h3 {
          font-size: 19px;
        }
      }
    }
  }
  .samples-page {
    #samples-panel {
      padding-left: 10%;
      .samples-content {
        .samples {
          .product-list-container {
            .samples__products-list {
              li {
                margin-right: 3em;
              }
            }
          }
        }
      }
      .continue-shopping {
        &__container--outer {
          margin-top: 2em;
          margin-bottom: 2em;
          .sample_popup_buttons {
            display: inline-block;
            margin-top: 2em;
            margin-bottom: 2em;
          }
          .sample_page_buttons {
            display: inline-block;
            float: left;
            margin-right: 2em;
          }
        }
        &__container--inner {
          width: 50%;
          .add-button {
            float: left;
            margin-right: 2em;
          }
        }
      }
    }
  }
  html.no-js .panel--sign-in #forgot-pw-note {
    display: none;
  }
  /* SIGN IN PANEL */
  #sign-in-paypal-panel {
    header {
      #return-account-h,
      #new-account-h {
        background: none;
      }
    }
    &.paypal.finished {
      display: none;
    }
  }
  .checkout-panel--shipping {
    margin: 0;
    p {
      font-weight: normal;
    }
  }
  .panel--sign-in {
    .forgot-pass {
      margin-top: 1em;
    }
    .label-content {
      display: none;
    }
    &__return-user,
    &__new-account {
      width: 100%;
      @include breakpoint($medium-up) {
        width: 50%;
      }
      &__header {
        background: none;
      }
    }
    &__return-user {
      border-right-width: 0;
    }
    input {
      width: 100%;
    }
    img.img_mail {
      float: left;
      margin-right: 0.3em;
    }
    .new-or-returning-radios {
      margin-bottom: 1em;
      input {
        display: inline-block;
        width: auto;
        padding: 0;
        margin-top: -0.3em;
      }
      label {
        display: inline-block;
        width: auto;
        padding: 0;
      }
      fieldset {
        float: left;
        width: 50%;
      }
    }
  }
  #registration-panel {
    &.checkout-panel {
      &.finished,
      &.collapsed {
        display: none;
      }
    }
    .how-will-we-use {
      clear: right;
      float: right;
      width: 24em;
      border: 1px solid $color-black;
      padding: 1em;
      h3 {
        margin: 0 0 1em;
      }
      p {
        margin: 0;
      }
    }
    .button-wrapper {
      text-align: center;
    }
  }
  .why-create-account-overlay {
    width: 300;
    height: 100;
  }
  @media (max-width: 480px) {
    .sign-in-panel > .column {
      float: none;
      width: 100%;
      margin-bottom: 0;
      padding-bottom: 0;
      border-right-width: 0;
      border-bottom: 1px solid #ccc;
    }
  }
}

.checkout-panel {
  @include clearfix;
  position: relative;
  padding: 1em;
  border-bottom: 1px solid $color-light-gray;
  &__content {
    .btn {
      width: 50%;
    }
    &.links-content {
      a {
        font-size: 14px;
      }
      p {
        margin-bottom: 0;
      }
    }
    @include breakpoint($medium-up) {
      border-bottom-width: 0px;
    }
  }
}

body#confirm {
  &.perlgem {
    &.checkout {
      .page-wrapper {
        .site-content {
          .checkout--confirmation-page {
            .checkout__confirm-container {
              .checkout__content {
                &.column {
                  &.left {
                    width: 100%;
                    border: none;
                    .checkout-panel--sign-in-confirmation {
                      .sign-in-confirmation-content {
                        form#completed_transaction_signin {
                          .form-item {
                            &.email-address {
                              margin: 2% 0;
                              label {
                                font-weight: bold;
                              }
                            }
                            &.password {
                              width: 65%;
                              margin-bottom: 1%;
                            }
                          }
                        }
                      }
                    }
                    #registration-panel {
                      .registration-content {
                        padding-top: 0px;
                        #completed_checkout_registration {
                          .registration-content__create-account {
                            .form-item,
                            h4 {
                              margin-top: 2%;
                            }
                            .registration-form-container {
                              div.text {
                                width: 50%;
                                input {
                                  width: 50%;
                                }
                              }
                            }
                            .create-account {
                              width: 50%;
                              input {
                                width: 65%;
                              }
                            }
                            .promotions-container {
                              .email_promotions {
                                .sms-mobile {
                                  .sms-mobile-phone {
                                    margin-left: -7px;
                                    margin-top: 23px;
                                    overflow: inherit;
                                    padding-left: 0;
                                    padding-top: 0;
                                    width: 200px;
                                    text-align: left;
                                    .sms_input {
                                      width: 100%;
                                    }
                                    label.sms_label:after {
                                      display: none;
                                    }
                                  }
                                  &-prefix {
                                    margin-top: 23px;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .checkout__sidebar {
              &.column {
                &.right {
                  display: none;
                }
              }
            }
          }
          .site-footer {
            &.container--relative {
              margin-top: 200px;
            }
          }
        }
        input,
        select {
          &:required:invalid:not(:checked).checkedEmpty {
            border: 1px solid $color-red;
          }
        }
        input[type='checkbox']:required:not(:checked) {
          &.checkedEmpty ~ label {
            color: #e20400;
          }
        }
      }
    }
  }
}
