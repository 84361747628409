.site-header {
  &__main {
    @include breakpoint($medium-up) {
      height: 65px;
    }
  }
  #csr_header_holder {
    position: absolute;
    top: 65px;
    left: 0px;
    width: 100%;
    z-index: 9999;
    #csr_header {
      color: #000000;
      width: 750px;
      font-family: 'AkzidenzGrotesk', arial, sans-serif;
      background-color: #f7f7f7;
      th,
      td {
        font-family: 'AkzidenzGrotesk', arial, sans-serif;
      }
      a {
        font-size: 1.25em;
        display: block;
        text-decoration: none;
      }
      a:hover {
        text-decoration: underline;
      }
      &.shadow {
        -webkit-box-shadow: 3px 3px 4px 5px #ccc;
        -moz-box-shadow: 3px 3px 4px 5px #ccc;
        box-shadow: 3px 3px 4px 5px #ccc;
      }
      .csr_table {
        width: 100%;
        margin: 0;
        table-layout: auto;
        line-height: normal;
        td {
          border-left: 1px red solid;
          padding: 0 20px;
          border-bottom: none;
        }
        #csr_info_box {
          border-left: none;
        }
        #csr_dragger {
          background: red;
          width: 40px;
          border: none;
          cursor: move;
          padding: 0;
        }
      }
    }
  }
}
